@import "../../../styles/variables";

#app-layout {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: $black;

  .page {
    flex-grow: 1;
  }
}
