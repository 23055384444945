@import "variables";

.modal {
  .ant-modal {
    .ant-modal-content {
      background: none;
      box-shadow: none;
      padding: 0;

      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .image {
          margin-bottom: 32px;
        }

        .title {
          margin-top: 0;
          margin-bottom: 24px;
        }

        .text {
          margin-top: 0;
          margin-bottom: 32px;
        }
      }
    }
  }
}
