@import "../../../styles/variables";

#app-button {
  box-shadow: none;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.16px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 180px;

  .btn-icon {
    font-size: 20px;
  }

  span:not(.ant-btn-icon, .btn-icon) {
    color: $black;
    line-height: 1.5;
  }

  &:disabled {
    background-color: $dark;

    span:not(.ant-btn-icon, .btn-icon) {
      color: $slateDarkHover;
    }
  }
}
