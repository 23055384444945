@import "variables";

.form {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 60px $black inset !important;
    -webkit-text-fill-color: $white;
    transition: none;
  }

  .ant-form {
    $height: 48px;
    $width: 100%;
    $border-radius: 6px;
    .text {
      font-weight: 500;
      line-height: 20px;
      text-align: center;
      letter-spacing: -0.14px;
    }

    .ant-input-group-wrapper {
      height: $height;
      width: $width;

      .ant-input {
        border: 1px solid $white;
        border-radius: 0px $border-radius $border-radius 0px;
        background-color: $black;
        border-left-color: $slateDark;
        color: $white;
        height: $height;

        &::placeholder {
          color: $white;
        }
        &:focus {
          box-shadow: none;
        }
      }

      .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus {
        box-shadow: none;
      }

      .ant-input-group-addon {
        border: 1px solid $white;
        border-radius: $border-radius 0px 0px $border-radius;
        border-right: none;
        background-color: $dark;
        color: $slateDarkHover;
      }
    }

    .link-btn span {
      border-radius: 6px;
      color: $slateDark10;
    }
  }
}
