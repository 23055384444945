@import 'styles/bootstrap-grid.min.css';
@import 'swiper/swiper.min.css';
@import 'styles/form';
@import 'styles/modal';
@import 'styles/table';
@import 'styles/colors';

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800;900&display=swap');

body {
  @extend .form;
  @extend .modal;
  @extend .table;
  margin: 0;
  font-family: 'Inter', -apple-system, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  color: $white;
}

#app {
  .app-loading {
    display: flex;
    justify-content: center;
    min-height: 100vh;
  }
}
