$primary-color: #69e391;
$white: #ffffff;
$black: #000000;
$error: #541b1f;
$red: #f2555a;
$success: #22492e;
$dark: #151718;
$purple: #af52de;
$slateDark: #3a3f42;
$slateDarkHover: #4c5155;
$slateDarkAlpha: #d5feff08;
$slateDarkAlpha2: #d5feff08;
$slateDarkAlpha7: #e0f3ff2e;
$slateDark10: #787f85;
$slateDark11: #9ba1a6;
$slateDark5: #2b2f31;
$slateDark6: #313538;
