@import "../../../styles/variables";

.api-error-modal {
  .ant-modal-content {
    .ant-modal-body {
      .content {
        .image {
          width: 80px;
        }

        .title {
          font-size: 24px;
          font-weight: 600;
          letter-spacing: -0.384px;
          margin-bottom: 12px;
        }

        .text {
          font-size: 16px;
          font-weight: 400;
          letter-spacing: -0.16px;
          margin-bottom: 24px;
        }

        .anticon {
          font-size: 48px;
          color: $error;
          margin-bottom: 24px;
        }
      }
    }
  }
}
