// TODO Texts
.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.text-primary {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
}
